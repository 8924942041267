import * as yup from "yup";

export const registrationValidation = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Passwords must match")
    .required("Confirm Password is required"),
  phoneNumber: yup
    .number()
    .typeError("Phone Number must be a number")
    .required("Phone Number is required")
    .test(
      "len",
      "Phone Number must be exactly 11 digits",
      (val) => val !== undefined && val.toString().length === 10
    ),
});

export const loginValidation = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
  // .matches(
  //   /^(?=.*\d)(?=.*\W)(?!.*\s).{8,}$/,
  //   "Password must contain at least one digit, one special character, and be at least 8 characters long"
  // ),
});

export const schoolValidation = yup.object().shape({
  schoolName: yup.string().required("School name is required"),
  address: yup.string().required("Address is required"),
});

export const adminValidation = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  schoolId: yup.string().required("School is required"),
});
