import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useColors } from "../utils/useColor";
import useStyles from "../utils/useStyles";

import { Link, useNavigate } from "react-router-dom";
import { InputText } from "../Components/TextFields/InputText";
import ButtonComp from "../Components/Buttons/ButtonComp";
import AxiosInstance from "../hooks/axiosInstance";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useSelector, useDispatch } from "react-redux";
import SnackBar from "../Components/Modals/SnackBar";
import {
  clearMessage,
  setLoading,
  setMessage,
  setSubmitted,
  setSuccess,
} from "../utils/formSlice";
import useAdmin from "../hooks/useAdmin";
import { loginValidation } from "../utils/FormalidateSchema";

const Login = () => {
  const { primary } = useColors();

  const style = useStyles();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading } = useSelector((state: any) => state.form);

  const updateAuthState = useAdmin((state) => state.updateAuthState);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidation,
    onSubmit: (value) => {
      dispatch(setLoading(true));
      AxiosInstance.post("/admins/login", value)
        .then(async (res) => {
          dispatch(setMessage(res.data.message));
          dispatch(setSuccess(true));
          dispatch(setSubmitted(true));
          dispatch(setLoading(false));
          formik.resetForm();
          await updateAuthState(res.data);
          setTimeout(() => {
            navigate("/dashboard");
            dispatch(clearMessage());
          }, 3000);
        })
        .catch((error) => {
          dispatch(setSuccess(false));
          dispatch(setSubmitted(false));
          dispatch(setLoading(false));
          dispatch(setMessage(error.response.data.error));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: "20px",
        }}
      >
        <Typography sx={style.welcomText}>Welcome Back</Typography>
      </Box>
      <Box sx={{ mt: 3, mx: "20px" }}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Box sx={{ mb: 3 }}>
            <InputText
              id="email"
              label="Email address"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <InputText
              id="password"
              label="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Box>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <FormControlLabel control={<Checkbox />} label="Remember me" />

            <Link to="/forget-password" style={{ textDecoration: "none" }}>
              <Typography
                variant="button"
                textAlign="right"
                component="a"
                sx={{ cursor: "pointer" }}
                // onClick={NavigatePassword}
              >
                Forget Password?
              </Typography>
            </Link>
          </Stack>
          <Box>
            {isLoading ? (
              <LoadingButton loading variant="outlined" fullWidth>
                Submit
              </LoadingButton>
            ) : (
              <ButtonComp
                text="Login"
                type="submit"
                variant="contained"
                background={primary.dark}
                fullWidth
                disableElevation
              />
            )}
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Login;
