import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { formatNumber } from "../utils/utils";
import { formFee } from "../utils/constants";
import ButtonComp from "../Components/Buttons/ButtonComp";
import useStyles from "../utils/useStyles";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import AxiosInstance from "../hooks/axiosInstance";
import {
  setLoading,
  setMessage,
  setSuccess,
  setSubmitted,
  clearMessage,
} from "../utils/formSlice";
import SnackBar from "../Components/Modals/SnackBar";
import { LoadingButton } from "@mui/lab";
import useAdmin from "../hooks/useAdmin";

const PaymentPage = () => {
  const style = useStyles();
  const user = useAdmin((state) => state.user);
  const dispatch = useDispatch();

  const { isLoading, isSubmitted, isSuccess, ismessage } = useSelector(
    (state: any) => state.form
  );

  const formik = useFormik({
    initialValues: {
      amount: formFee,
    },
    onSubmit: (value) => {
      dispatch(setLoading(true));
      AxiosInstance.post("/students/payment", value)
        .then((res) => {
          if (res && res.data.status === "success") {
            window.location.href = res.data.data.link;
          }
        })
        .catch((error) => {
          dispatch(setSuccess(false));
          dispatch(setSubmitted(false));
          dispatch(setLoading(false));
          dispatch(setMessage(error.response.data.error));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });

  const handleDownloadReceipt = () => {
    alert("Working");
  };
  return (
    <>
      <SnackBar
        isSubmitted={isSubmitted}
        isSuccess={isSuccess}
        ismessage={ismessage}
      />
      <Stack
        sx={{
          background: "#fff",
          mt: "20px",
          height: "250px",
          display: "flex",
          flexWrap: "wrap",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={style.welcomText}>Application form fee</Typography>
          <Typography sx={style.logoSmallText}>
            {user.isPaid === true
              ? "Payment successfully completed"
              : "Click here to make your payment"}
          </Typography>
          <Typography sx={style.welcomText}>{formatNumber(formFee)}</Typography>
          <form onSubmit={formik.handleSubmit} noValidate>
            {isLoading ? (
              <LoadingButton loading variant="outlined" fullWidth>
                Submit
              </LoadingButton>
            ) : user.isPaid === true ? (
              <ButtonComp
                type="button"
                variant="contained"
                text="Download Payment Receipt"
                onClick={handleDownloadReceipt}
              />
            ) : (
              <ButtonComp
                type="submit"
                variant="contained"
                text="Make Payment"
              />
            )}
          </form>
        </Box>
      </Stack>
    </>
  );
};

export default PaymentPage;
