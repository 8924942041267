import "@fontsource/quicksand";
import React from "react";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import RoutesComponents from "./routes/RoutesComponents";
import { Provider } from "react-redux";
import { store } from "./utils/store";

const App = () => {
  const theme = createTheme({
    typography: {
      fontFamily: "Quicksand",
    },
    palette: {
      primary: {
        main: "#CC1120",
      },
      secondary: {
        main: "#1E1F6F",
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <HelmetProvider>
            <BrowserRouter>
              <RoutesComponents />
            </BrowserRouter>
          </HelmetProvider>
        </Provider>
      </ThemeProvider>
    </>
  );
};

export default App;
