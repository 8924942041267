import { Navigate, Outlet } from "react-router-dom";
import useAdmin from "../hooks/useAdmin";

const AuthRoutes = () => {
  const user = useAdmin((state) => state.user);

  return user ? <Navigate to="/dashboard" replace /> : <Outlet />;
};

export default AuthRoutes;
