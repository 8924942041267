import { useColors } from "./useColor";

const useStyles = () => {
  const { primary } = useColors();
  return {
    text: {
      fontWeight: 600,
      color: "#5D5D5E",
      textAlign: 'center'
    },
    smallHead: {
      fontWeight: 900,
      color: primary.dark,
      fontSize: "1.2rem",
    },
    logoSmallText: {
      color: primary.dark,
      fontWeight: 900,
    },
    welcomText: {
      fontWeight: 900,
      fontSize: "1.8em",
      textTransform: 'capitalize'
    },
    error404: {
      fontWeight: 900,
      color: "#5D5D5E",
      fontSize: "1.2em",
      mt: "20px",
    },
  };
};

export default useStyles;
