import CryptoJS from "crypto-js";

const key = `${process.env.REACT_APP_ENCRYPT_KEY}`;

// Encrypt function
export const encryptData = (data: any) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    key
  ).toString();
  return encryptedData;
};

// Decrypt function
export const decryptData = (encryptedData: string) => {
  if (!encryptedData) {
    return;
  }

  const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key);
  const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};
