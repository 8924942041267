import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { HeaderTextButton } from "../Header/HeaderTextButton";
import { uploadDocumant } from "../../utils/NigeriaState";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import ButtonComp from "../Buttons/ButtonComp";
import { useDispatch, useSelector } from "react-redux";
import { useColors } from "../../utils/useColor";
import { InputText } from "../TextFields/InputText";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Link } from "react-router-dom";
import {
  setLoading,
  setMessage,
  setSuccess,
  setSubmitted,
  clearMessage,
} from "../../utils/formSlice";
import axiosInstance from "../../hooks/axiosInstance";
import useAdmin from "../../hooks/useAdmin";
import { IStudents } from "../../utils/type";
import { getFileURL } from "../../utils/utils";

interface Props {
  handleNext: () => void;
  details: any;
}

interface Document {
  type: string;
  file: File;
  base64: string;
}
const AppUpload: FC<Props> = ({ details, handleNext }) => {
  const updateUser = useAdmin((state) => state.updateUser);
  const dispatch = useDispatch();
  const { primary, error } = useColors();
  const { isLoading } = useSelector((state: any) => state.form);
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<Document[]>([]);

  const formik = useFormik({
    initialValues: {
      documents: {
        name: "",
        file: null,
      },
    },
    onSubmit: (value) => {
      const formData = {
        documents: uploadedFiles.map((fileData) => ({
          type: fileData.type,
          file: fileData.base64,
        })),
      };

      dispatch(setLoading(true));
      axiosInstance
        .put(`/students`, formData)
        .then((res) => {
          dispatch(setMessage(res.data.message));
          updateUser(res.data.upload);
          dispatch(setSuccess(true));
          dispatch(setSubmitted(true));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
          handleNext();
        })
        .catch((error) => {
          dispatch(setSuccess(false));
          dispatch(setSubmitted(false));
          dispatch(setLoading(false));
          dispatch(setMessage(error.response.data.error));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });

  const data = details as IStudents;
  const [isSubmitted, setIsSubmitted] = useState<Boolean>();

  useEffect(() => {
    if (data?.documents) {
      setIsSubmitted(data.isSubmitted);
      const documentsWithBase64 = data.documents.map(
        (doc: any) => ({
          type: doc.type,
          file: doc.type, // dummy file object, replace as needed
          base64: doc.file, // assuming this is the base64 string from the database
        })
      );
      setUploadedFiles(documentsWithBase64);
    }
  }, [data]);

  const handleDocumentTypeChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    setSelectedDocumentType(value);
    formik.setFieldValue("documents.name", value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setUploadedFiles([
          ...uploadedFiles,
          { type: selectedDocumentType, file, base64: base64String },
        ]);
        setSelectedDocumentType("");
        formik.setFieldValue("documents.file", file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = (index: number) => {
    setUploadedFiles(uploadedFiles.filter((_, i) => i !== index));
  };

  const availableDocumentTypes = uploadDocumant.filter(
    (type) => !uploadedFiles.some((file) => file.type === type)
  );

  return (
    <>
      <Box sx={{ mx: "90px" }}>
        <HeaderTextButton headerName="Documents Upload" />
        <form onSubmit={formik.handleSubmit}>
          {!isSubmitted && (
            <Box sx={{ my: "15px", width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-select-small-label">
                  Document Type
                </InputLabel>
                <Select
                  labelId="Document"
                  id="documentType"
                  name="documentType"
                  label="Document Type"
                  value={formik.values.documents.name}
                  onChange={handleDocumentTypeChange}
                >
                  {availableDocumentTypes.map((data, index) => (
                    <MenuItem key={index} value={data}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          {selectedDocumentType && (
            <Box sx={{ mb: "15px", width: "100%" }}>
              <InputText
                type="file"
                id="file"
                label=""
                onChange={handleFileChange}
                accept="application/pdf"
              />
            </Box>
          )}

          <Box>
            {uploadedFiles.map((fileData, index) => (
              <Box
                key={index}
                sx={{ mb: "15px", display: "flex", alignItems: "center" }}
              >
                <Box
                  sx={{
                    width: "30%",
                    background: "#F1F5F8",
                    height: "50px",
                    m: "auto",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: "20px",
                  }}
                >
                  <Box>{fileData.type}</Box>
                  <Box>
                    <Link
                      to={getFileURL(fileData.file)}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <PictureAsPdfOutlinedIcon />
                    </Link>
                  </Box>
                  <Box
                    onClick={() => handleDelete(index)}
                    sx={{ cursor: "pointer" }}
                  >
                    <DeleteForeverOutlinedIcon sx={{ color: error.dark }} />
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              width: "40%",
              m: "auto",
              pt: "30px",
            }}
          >
            {isLoading ? (
              <LoadingButton loading variant="outlined" fullWidth>
                Submit
              </LoadingButton>
            ) : (
              <ButtonComp
                text="Save and Continue"
                type="submit"
                disabled={isSubmitted}
                variant="contained"
                background={primary.dark}
                fullWidth
                disableElevation
              />
            )}
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AppUpload;
