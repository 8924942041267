import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import ButtonComp from "../Buttons/ButtonComp";
import useStyles from "../../utils/useStyles";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import axiosInstance from "../../hooks/axiosInstance";
import {
  setLoading,
  setMessage,
  setSuccess,
  setSubmitted,
  clearMessage,
} from "../../utils/formSlice";
import useAdmin from "../../hooks/useAdmin";
import { FC, useEffect, useState } from "react";
import { IStudents } from "../../utils/type";

interface Props {
  details: any;
}

const AppConfirm: FC<Props> = ({ details }) => {
  const { isLoading } = useSelector((state: any) => state.form);
  const updateUser = useAdmin((state) => state.updateUser);
  const style = useStyles();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      isSubmitted: true,
    },
    onSubmit: (value) => {
      dispatch(setLoading(true));
      axiosInstance
        .put(`/students`, value)
        .then((res) => {
          dispatch(setMessage(res.data.message));
          updateUser(res.data.upload);
          dispatch(setSuccess(true));
          dispatch(setSubmitted(true));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        })
        .catch((error) => {
          dispatch(setSuccess(false));
          dispatch(setSubmitted(false));
          dispatch(setLoading(false));
          dispatch(setMessage(error.response.data.error));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });

  const data = details as IStudents;
  const [isSubmitted, setIsSubmitted] = useState<Boolean>();

  useEffect(() => {
    if (data) {
      setIsSubmitted(data.isSubmitted);
    }
  }, [data]);
  return (
//     <PDFDownloadLink
//     document={
//       <ContestantsPDF
//         contestants={dataContestant}
//         accredicted={accredited}
//         voted={voted}
//       />
//     }
//     fileName="Contestants-Result"
//   >
//     <Button
//       variant="contained"
//       sx={{
//         background: theme.palette.primary.dark,
//         color: "#fff",
//         fontWeight: "bold",
//       }}
//       disableElevation
//     >
//       Download Result
//     </Button>
//   </PDFDownloadLink>
    <>
      <Box sx={{ mx: "90px" }}>
        <Stack
          sx={{
            background: "#fff",
            mt: "20px",
            height: "250px",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={style.welcomText}>{isSubmitted ? 'Download Form Data' : 'Final Submission'}</Typography>
            <Typography sx={style.logoSmallText}>
              {isSubmitted ? 'Make Sure to download payment receipt from Payment page' : 'Make sure all data submitted are correct!'}
            </Typography>
            {!isSubmitted && (
                <Typography sx={style.logoSmallText}>
                Correction(s) will not be allowed after final submission.
              </Typography>
            )}

            <form onSubmit={formik.handleSubmit} noValidate>
              {isLoading ? (
                <LoadingButton loading variant="outlined" fullWidth>
                  Submit
                </LoadingButton>
              ) : isSubmitted ? (
                <ButtonComp
                  type="submit"
                  variant="contained"
                  text="Download "
                />
              ) : (
                <ButtonComp
                  type="submit"
                  variant="contained"
                  text="Submit finally"
                />
              )}
            </form>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default AppConfirm;
