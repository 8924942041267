import { create } from "zustand";
import { IAdmin } from "../utils/type";
import { decryptData, encryptData } from "../utils/Encryptor";

const adminData = localStorage.getItem("ADMIN");

interface IAdminData {
  user: IAdmin;
  accessToken: string;
}

interface Store {
  user: IAdmin;
  updateUser: (user: IAdmin) => void;
  updateAuthState: (data?: IAdminData) => void;
}

const useAdmin = create<Store>(
  (set): Store => ({
    user: adminData && decryptData(adminData),
    updateAuthState: (data?: IAdminData) => {
      if (data) {
        localStorage.setItem("TOKEN", encryptData(data.accessToken));
        localStorage.setItem("ADMIN", encryptData(data.user));
      } else {
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("ADMIN");
      }
      set((state) => ({
        user: data?.user,
      }));
    },
    updateUser: (user: IAdmin) => {
      set((state) => {
        const newData = { ...state.user, ...user };
        localStorage.setItem("ADMIN", encryptData(newData));
        return {
          user: newData,
        };
      });
    },
  })
);

export default useAdmin;
