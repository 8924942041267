import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Pages/Login";
import Error404 from "../Pages/Error404";
import HomeLayout from "../Components/Layout/HomeLayout";
import ForgetPassword from "../Pages/ForgetPassword";
import Dashboard from "../Pages/Dashboard";
import AuthLayout from "../Components/Layout/AuthLayout";
import Installation from "../Pages/Installation";
import AuthRoutes from "./AuthRoutes";
import ProtectedRoute from "./PortectedRoutes";
import Payment from "../Pages/Payment";
import PaymentVerify from "../Pages/PaymentVerify";

const RoutesComponents = () => {
  return (
    <Routes>
      <Route element={<AuthRoutes />}>
        <Route
          path="/"
          element={
            <HomeLayout title="Login">
              <Login />
            </HomeLayout>
          }
        />
        <Route
          path="/registration"
          element={
            <HomeLayout title="Registration">
              <Installation />
            </HomeLayout>
          }
        />
        <Route
          path="/forget-password"
          element={
            <HomeLayout title="Forget Password">
              <ForgetPassword />
            </HomeLayout>
          }
        />
      </Route>

      {/* Protected */}
      <Route element={<ProtectedRoute />}>
        <Route
          path="/dashboard"
          element={
            <AuthLayout title="Dashboard">
              <Dashboard />
            </AuthLayout>
          }
        />
        <Route
          path="/payment"
          element={
            <AuthLayout title="Payment">
              <Payment />
            </AuthLayout>
          }
        />
        <Route
          path="/payment/verify"
          element={
            <AuthLayout title="Payment">
              <PaymentVerify />
            </AuthLayout>
          }
        />
      </Route>

      <Route path="*" element={<Error404 title="Page not found" />} />
    </Routes>
  );
};

export default RoutesComponents;
