import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { HeaderTextButton } from "../Header/HeaderTextButton";
import { useFormik } from "formik";
import { InputText } from "../TextFields/InputText";
import { programme, states } from "../../utils/NigeriaState";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import ButtonComp from "../Buttons/ButtonComp";
import { useColors } from "../../utils/useColor";
import {
  setLoading,
  setMessage,
  setSuccess,
  setSubmitted,
  clearMessage,
} from "../../utils/formSlice";
import AxiosInstance from "../../hooks/axiosInstance";
import { IStudents } from "../../utils/type";
import useAdmin from "../../hooks/useAdmin";

interface Props {
  handleNext: () => void;
  details: any;
}

const AppInfo: FC<Props> = ({ handleNext, details }) => {
  const updateUser = useAdmin((state) => state.updateUser);
  const [selectedProgramme, setSelectedProgramme] = useState("");
  const [courses, setCourses] = useState<{ name: string }[]>([]);
  const { primary } = useColors();
  // const handleProgrammeChange = (event: SelectChangeEvent<string>) => {
  //   const selectedProgrammeName = event.target.value;
  //   setSelectedProgramme(selectedProgrammeName);
  //   formik.setFieldValue("programme", selectedProgrammeName);
  //   formik.setFieldValue("course", "");
  // };
  const setMultipleValues = (values: { [key: string]: any }) => {
    Object.keys(values).forEach((key) => {
      formik.setFieldValue(key, values[key]);
    });
  };
  const data = details as IStudents;
  const [isSubmitted, setIsSubmitted] = useState<Boolean>();
  const [pType, setPType] = useState("");

  const { isLoading } = useSelector((state: any) => state.form);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      programme: "",
      course: "",
      appType: "",
      secondary: {
        name: "",
        address: "",
        country: "",
        state: "",
        city: "",
        dateOfAttendance: "",
        contact: "",
      },
      postSecondary: {
        name: "",
        address: "",
        country: "",
        state: "",
        city: "",
        dateOfAttendance: "",
        course: "",
        grades: "",
      },
    },
    onSubmit: (value) => {
      dispatch(setLoading(true));
      AxiosInstance.put(`/students`, value)
        .then((res) => {
          dispatch(setMessage(res.data.message));
          updateUser(res.data.upload);
          dispatch(setSuccess(true));
          dispatch(setSubmitted(true));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
          handleNext();
        })
        .catch((error) => {
          dispatch(setSuccess(false));
          dispatch(setSubmitted(false));
          dispatch(setLoading(false));
          dispatch(setMessage(error.response.data.error));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });

  useEffect(() => {
    if (formik.values.programme || data) {
      const selectedProgrammeData = programme.find(
        (prog) => prog.name === (formik.values.programme || data.programme)
      );
      if (selectedProgrammeData) {
        setCourses(selectedProgrammeData?.course);
      }
    } else {
      setCourses([]);
    }
    if (formik.values.programme || data?.programme) {
      setPType(formik.values.programme || data.programme);
    }

    console.log(data)

    if (data?.programme) {
      setIsSubmitted(data.isSubmitted);
      setMultipleValues({
        programme: data?.programme,
        course: data?.course,
        appType: data?.appType,
        "secondary.name": data?.secondary?.name,
        "secondary.address": data?.secondary?.address,
        "secondary.country": data?.secondary?.country,
        "secondary.state": data?.secondary?.state,
        "secondary.city": data?.secondary?.city,
        "secondary.dateOfAttendance": data?.secondary?.dateOfAttendance,
        "secondary.contact": data?.secondary?.contact,
        "postSecondary.name": data?.postSecondary?.name,
        "postSecondary.address": data?.postSecondary?.address,
        "postSecondary.country": data?.postSecondary?.country,
        "postSecondary.state": data?.postSecondary?.state,
        "postSecondary.city": data?.postSecondary?.city,
        "postSecondary.dateOfAttendance": data?.postSecondary?.dateOfAttendance,
        "postSecondary.course": data?.postSecondary?.course,
        "postSecondary.grades": data?.postSecondary?.grades,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [

    data,
    formik.values.programme,
    // data.programme,
  ]);

  return (
    <>
      <Box sx={{ mx: "90px" }}>
        <HeaderTextButton headerName="Applicants Informations" />
        <Box sx={{ width: "100%" }}>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Box sx={{ mt: "20px" }}>
              <Box
                sx={{
                  mb: 1,
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  justifyContent: "space-between",
                  gap: { lg: 3, xs: 0 },
                }}
              >
                <Box sx={{ mb: "15px", width: "100%" }}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.programme &&
                      Boolean(formik.errors.programme)
                    }
                  >
                    <InputLabel id="demo-select-small-label">
                      Programme
                    </InputLabel>
                    <Select
                      labelId="Programme"
                      id="programme"
                      name="programme"
                      label="Programme"
                      value={formik.values.programme}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.programme &&
                        Boolean(formik.errors.programme)
                      }
                    >
                      {programme.map((prog, index) => (
                        <MenuItem key={index} value={prog.name}>
                          {prog.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.programme && formik.errors.programme && (
                    <Typography color="error" fontSize={"0.75rem"} ml={"14px"}>
                      {formik.errors.programme}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mb: "15px", width: "100%" }}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.course && Boolean(formik.errors.course)
                    }
                  >
                    <InputLabel id="demo-select-small-label">Course</InputLabel>
                    <Select
                      labelId="course"
                      id="course"
                      name="course"
                      label="course"
                      value={formik.values.course}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.course && Boolean(formik.errors.course)
                      }
                    >
                      {courses.map((course, index) => (
                        <MenuItem key={index} value={course.name}>
                          {course.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.course && formik.errors.course && (
                    <Typography color="error" fontSize={"0.75rem"} ml={"14px"}>
                      {formik.errors.course}
                    </Typography>
                  )}
                </Box>

                {pType !== "A-Level" && (
                  <Box sx={{ mb: "15px", width: "100%" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.appType && Boolean(formik.errors.appType)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        Application Type
                      </InputLabel>
                      <Select
                        labelId="Application Type"
                        id="appType"
                        name="appType"
                        label="Application Type"
                        value={formik.values.appType}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.appType &&
                          Boolean(formik.errors.appType)
                        }
                      >
                        <MenuItem value="UTME">UTME</MenuItem>
                        <MenuItem value="Transfer">Transfer</MenuItem>
                        <MenuItem value="Direct Entry">Direct Entry</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.appType && formik.errors.appType && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                      >
                        {formik.errors.appType}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
              <HeaderTextButton headerName="Secondary School Details" />
              <Box
                sx={{
                  mt: "20px",
                  mb: 1,
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  justifyContent: "space-between",
                  gap: { lg: 3, xs: 0 },
                }}
              >
                <InputText
                  id="secondary.name"
                  label="Name of School"
                  type="text"
                  value={formik.values.secondary.name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.secondary?.name &&
                    Boolean(formik.errors.secondary?.name)
                  }
                  helperText={
                    formik.touched.secondary?.name &&
                    formik.errors.secondary?.name
                  }
                />
                <InputText
                  id="secondary.address"
                  label="Address"
                  type="text"
                  value={formik.values.secondary.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.secondary?.address &&
                    Boolean(formik.errors.secondary?.address)
                  }
                  helperText={
                    formik.touched.secondary?.address &&
                    formik.errors.secondary?.address
                  }
                />
                <InputText
                  id="secondary.country"
                  label="Country"
                  type="text"
                  value={formik.values.secondary?.country}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.secondary?.country &&
                    Boolean(formik.errors.secondary?.country)
                  }
                  helperText={
                    formik.touched.secondary?.country &&
                    formik.errors.secondary?.country
                  }
                />
              </Box>
              <Box
                sx={{
                  mb: 1,
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  justifyContent: "space-between",
                  gap: { lg: 3, xs: 0 },
                }}
              >
                <Box sx={{ mb: "15px", width: "100%" }}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.secondary?.state &&
                      Boolean(formik.errors.secondary?.state)
                    }
                  >
                    <InputLabel id="demo-select-small-label">State</InputLabel>
                    <Select
                      labelId="State of Origin"
                      id="secondary.state"
                      name="secondary.state"
                      label="State of Origin"
                      value={formik.values.secondary?.state}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.secondary?.state &&
                        Boolean(formik.errors.secondary?.state)
                      }
                    >
                      {states.states.map((state: any) => (
                        <MenuItem key={state.code} value={state.name}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.secondary?.state &&
                    formik.errors.secondary?.state && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                      >
                        {formik.errors.secondary?.state}
                      </Typography>
                    )}
                </Box>
                <InputText
                  id="secondary.city"
                  label="City/Town/Village"
                  type="text"
                  value={formik.values.secondary?.city}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.secondary?.city &&
                    Boolean(formik.errors.secondary?.city)
                  }
                  helperText={
                    formik.touched.secondary?.city &&
                    formik.errors.secondary?.city
                  }
                />
                <InputText
                  id="secondary.dateOfAttendance"
                  label="Dates of Attendance"
                  type="date"
                  value={formik.values.secondary?.dateOfAttendance}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    formik.touched.secondary?.dateOfAttendance &&
                    Boolean(formik.errors.secondary?.dateOfAttendance)
                  }
                  helperText={
                    formik.touched.secondary?.dateOfAttendance &&
                    formik.errors.secondary?.dateOfAttendance
                  }
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                {" "}
                <InputText
                  id="secondary.contact"
                  label="School's Phone or Email Address (If any)"
                  type="text"
                  value={formik.values.secondary?.contact}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.secondary?.contact &&
                    Boolean(formik.errors.secondary?.contact)
                  }
                  helperText={
                    formik.touched.secondary?.contact &&
                    formik.errors.secondary?.contact
                  }
                />
              </Box>
              {formik.values.programme !== "A-Level" && (
                <>
                  <HeaderTextButton headerName="Post Secondary Courses (If any)" />
                  <Box
                    sx={{
                      mt: "20px",
                      mb: 1,
                      display: "flex",
                      flexDirection: { lg: "row", xs: "column" },
                      justifyContent: "space-between",
                      gap: { lg: 3, xs: 0 },
                    }}
                  >
                    <InputText
                      id="postSecondary.name"
                      label="Institution Attended"
                      type="text"
                      value={formik.values.postSecondary.name}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.postSecondary?.name &&
                        Boolean(formik.errors.postSecondary?.name)
                      }
                      helperText={
                        formik.touched.postSecondary?.name &&
                        formik.errors.postSecondary?.name
                      }
                    />
                    <InputText
                      id="postSecondary.address"
                      label="Address"
                      type="text"
                      value={formik.values.postSecondary.address}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.postSecondary?.address &&
                        Boolean(formik.errors.postSecondary?.address)
                      }
                      helperText={
                        formik.touched.postSecondary?.address &&
                        formik.errors.postSecondary?.address
                      }
                    />
                    <InputText
                      id="postSecondary.country"
                      label="Country"
                      type="text"
                      value={formik.values.postSecondary?.country}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.postSecondary?.country &&
                        Boolean(formik.errors.postSecondary?.country)
                      }
                      helperText={
                        formik.touched.postSecondary?.country &&
                        formik.errors.postSecondary?.country
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      mb: 1,
                      display: "flex",
                      flexDirection: { lg: "row", xs: "column" },
                      justifyContent: "space-between",
                      gap: { lg: 3, xs: 0 },
                    }}
                  >
                    <Box sx={{ mb: "15px", width: "100%" }}>
                      <FormControl
                        fullWidth
                        error={
                          formik.touched.postSecondary?.state &&
                          Boolean(formik.errors.postSecondary?.state)
                        }
                      >
                        <InputLabel id="demo-select-small-label">
                          State
                        </InputLabel>
                        <Select
                          labelId="State of Origin"
                          id="postSecondary.state"
                          name="postSecondary.state"
                          label="State of Origin"
                          value={formik.values.postSecondary?.state}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.postSecondary?.state &&
                            Boolean(formik.errors.postSecondary?.state)
                          }
                        >
                          {states.states.map((state: any) => (
                            <MenuItem key={state.code} value={state.name}>
                              {state.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formik.touched.postSecondary?.state &&
                        formik.errors.postSecondary?.state && (
                          <Typography
                            color="error"
                            fontSize={"0.75rem"}
                            ml={"14px"}
                          >
                            {formik.errors.postSecondary?.state}
                          </Typography>
                        )}
                    </Box>
                    <InputText
                      id="postSecondary.city"
                      label="City/Town"
                      type="text"
                      value={formik.values.postSecondary?.city}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.postSecondary?.city &&
                        Boolean(formik.errors.postSecondary?.city)
                      }
                      helperText={
                        formik.touched.postSecondary?.city &&
                        formik.errors.postSecondary?.city
                      }
                    />
                    <InputText
                      id="postSecondary.dateOfAttendance"
                      label="Dates of Attendance"
                      type="date"
                      value={formik.values.postSecondary?.dateOfAttendance}
                      onChange={formik.handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={
                        formik.touched.postSecondary?.dateOfAttendance &&
                        Boolean(formik.errors.postSecondary?.dateOfAttendance)
                      }
                      helperText={
                        formik.touched.postSecondary?.dateOfAttendance &&
                        formik.errors.postSecondary?.dateOfAttendance
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      mb: 1,
                      display: "flex",
                      flexDirection: { lg: "row", xs: "column" },
                      justifyContent: "space-between",
                      gap: { lg: 3, xs: 0 },
                    }}
                  >
                    <InputText
                      id="postSecondary.course"
                      label="Course of Study"
                      type="text"
                      value={formik.values.postSecondary?.course}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.postSecondary?.course &&
                        Boolean(formik.errors.postSecondary?.course)
                      }
                      helperText={
                        formik.touched.postSecondary?.course &&
                        formik.errors.postSecondary?.course
                      }
                    />
                    <InputText
                      id="postSecondary.grades"
                      label="Grades"
                      type="text"
                      value={formik.values.postSecondary?.grades}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.postSecondary?.grades &&
                        Boolean(formik.errors.postSecondary?.grades)
                      }
                      helperText={
                        formik.touched.postSecondary?.grades &&
                        formik.errors.postSecondary?.grades
                      }
                    />
                  </Box>
                </>
              )}
            </Box>
            <Box
              sx={{
                width: "40%",
                m: "auto",
                pt: "30px",
              }}
            >
              {isLoading ? (
                <LoadingButton loading variant="outlined" fullWidth>
                  Submit
                </LoadingButton>
              ) : (
                <ButtonComp
                  text="Save and Continue"
                  type="submit"
                  disabled={isSubmitted}
                  variant="contained"
                  background={primary.dark}
                  fullWidth
                  disableElevation
                />
              )}
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AppInfo;
