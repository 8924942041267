import { TextField } from "@mui/material";
import { FC } from "react";

interface InputTextProps {
  id: string | undefined;
  label: string;
  type?: string;
  onChange?: (e: any) => void;
  [x: string]: any;
}

export const InputText: FC<InputTextProps> = ({
  id,
  label,
  type,
  onChange,
  ...props
}) => {
  return (
    <>
      <TextField
        autoComplete="off"
        id={id}
        label={label}
        type={type}
        fullWidth
        onChange={onChange}
        {...props}
        sx={{
          "& input": {
            padding: "14px", // Adjust the padding to change the height
          },
          mb: "10px",
        }}
      />
    </>
  );
};
