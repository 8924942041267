import { Box } from "@mui/material";
import ButtonComp from "../Components/Buttons/ButtonComp";
import { InputText } from "../Components/TextFields/InputText";
import { useColors } from "../utils/useColor";
import { useFormik } from "formik";
import { registrationValidation } from "../utils/FormalidateSchema";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  setLoading,
  setMessage,
  setSubmitted,
  setSuccess,
} from "../utils/formSlice";
import AxiosInstance from "../hooks/axiosInstance";
import { useNavigate } from "react-router-dom";

const Installation = () => {
  const { primary } = useColors();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading } = useSelector((state: any) => state.form);

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: registrationValidation,
    onSubmit: (value) => {
      dispatch(setLoading(true));
      AxiosInstance.post("/students", value)
        .then((res) => {
          dispatch(setMessage(res.data.message));
          dispatch(setSuccess(true));
          dispatch(setSubmitted(true));
          dispatch(setLoading(false));
          formik.resetForm();
          setTimeout(() => {
            dispatch(clearMessage());
            navigate("/");
          }, 3000);
        })
        .catch((error) => {
          dispatch(setSuccess(false));
          dispatch(setSubmitted(false));
          dispatch(setLoading(false));
          dispatch(setMessage(error.response.data.error));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });
  return (
    <>
      <Box sx={{ mt: 3, mx: "20px", height: "100%" }}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Box sx={{ mb: 2 }}>
            <InputText
              id="email"
              label="Email address"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Box>
          <Box
            sx={{
              mb: 2,
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              gap: 3,
            }}
          >
            <InputText
              id="firstName"
              label="First Name"
              type="text"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <InputText
              id="lastName"
              label="Last Name"
              type="text"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <InputText
              id="phoneNumber"
              label="Phone Number"
              type="number"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
            />
          </Box>
          <Box
            sx={{
              mb: 2,
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              gap: 3,
            }}
          >
            <InputText
              id="password"
              label="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={
                formik.touched.password && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.password && formik.errors.password}
            />
            <InputText
              id="confirmPassword"
              label="Confirm Password"
              type="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />
          </Box>
          <Box>
            {isLoading ? (
              <LoadingButton loading variant="outlined" fullWidth>
                Submit
              </LoadingButton>
            ) : (
              <ButtonComp
                text="Create Account"
                type="submit"
                variant="contained"
                background={primary.dark}
                fullWidth
                disableElevation
              />
            )}
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Installation;
