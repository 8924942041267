// import axios from "axios";
// import { decryptData } from "../utils/Encryptor";

// const BACKEND_APP_URL = process.env.REACT_APP_PUBLIC_URL;
// const key = localStorage.getItem("DBMS-TOKEN") as string;
// const token = decryptData(key);

// const AxiosInstance = axios.create({
//   baseURL: BACKEND_APP_URL,
//   headers: {
//     common: {
//       Authorization: `Bearer ${token}`,
//     },
//   },
// });

// AxiosInstance.interceptors.request.use(
//   (config: any) => {
//     // Check if the request data is FormData or JSON object
//     if (config.data instanceof FormData) {
//       // For FormData requests, set 'multipart/form-data' Content-Type
//       config.headers["Content-Type"] = "multipart/form-data";
//     } else {
//       // For JSON requests, set 'application/json' Content-Type
//       config.headers["Content-Type"] = "application/json";
//     }
//     return config;
//   },
//   (error: any) => {
//     return Promise.reject(error);
//   }
// );

// export default AxiosInstance;

import axios from "axios";
import { decryptData } from "../utils/Encryptor";

const BACKEND_APP_URL = process.env.REACT_APP_PUBLIC_URL;

const createAxiosInstance = () => {
  const encryptedToken = localStorage.getItem("TOKEN");
  let token;

  if (encryptedToken) {
    try {
      token = decryptData(encryptedToken);
    } catch (error) {
      console.error("Error decrypting token:", error);
    }
  }

  const instance = axios.create({
    baseURL: BACKEND_APP_URL,
    headers: {
      common: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  // Add request interceptor
  instance.interceptors.request.use(
    (config: any) => {
      // Check if the request data is FormData or JSON object
      if (config.data instanceof FormData) {
        // For FormData requests, set 'multipart/form-data' Content-Type
        config.headers["Content-Type"] = "multipart/form-data";
      } else {
        // For JSON requests, set 'application/json' Content-Type
        config.headers["Content-Type"] = "application/json";
      }

      // Dynamically set Authorization header
      const encryptedToken = localStorage.getItem("TOKEN");
      if (encryptedToken) {
        try {
          const token = decryptData(encryptedToken);
          config.headers["Authorization"] = `Bearer ${token}`;
        } catch (error) {
          console.error("Error decrypting token:", error);
        }
      }

      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default createAxiosInstance();
