import { Stack, Typography, Box } from "@mui/material";
import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Identity } from "../utils/constants";
import image404 from "../Components/assets/images/404.png";
import useStyles from "../utils/useStyles";

interface Error404Props {
  title: string;
}

const Error404: FC<Error404Props> = ({ title }) => {
  const style = useStyles();
  return (
    <>
      <Helmet>
        <title>{`${Identity} | ${title}`}</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Stack
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <img
            src={image404}
            alt=""
            style={{ width: "100%", objectFit: "cover" }}
          />
        </Box>
        <Typography sx={style.error404}>404 | Page not found</Typography>
      </Stack>
    </>
  );
};

export default Error404;
