export const formatNumber = (number: number): string => {
  return number.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
    currencyDisplay: "symbol",
  }); // Converts number to a string with currency symbol and thousands separator
};

export const getFileURL = (file: string | File) => {
  if (typeof file === "string") {
    return file.startsWith("data:")
      ? file
      : `data:application/pdf;base64,${file}`;
  }
  return URL.createObjectURL(file);
};
