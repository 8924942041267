import { Avatar, CircularProgress, IconButton } from "@mui/material";
import React, { FC, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface AvatarUploaderProps {
  imageUrl?: string;
  handleUpload: (data: string) => void;
}

const AvatarUploader: FC<AvatarUploaderProps> = ({
  imageUrl,
  handleUpload,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isLoading } = useSelector((state: any) => state.form);
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        await setSelectedImage(reader.result as string);
        handleUpload(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <div style={{ position: "relative", display: "inline-block" }}>
        <Avatar
          alt="User Avatar"
          src={selectedImage || imageUrl}
          sx={{ width: 150, height: 150 }}
        />
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <IconButton
          style={{ position: "absolute", top: 0, right: 0 }}
          onClick={handleUploadClick}
        >
          <CloudUploadIcon color="primary" />
        </IconButton>
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageUpload}
          ref={fileInputRef}
        />
      </div>
    </>
  );
};

export default AvatarUploader;
