import { Box, Typography, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { useColors } from "../utils/useColor";
import useStyles from "../utils/useStyles";
import { InputText } from "../Components/TextFields/InputText";
import ButtonComp from "../Components/Buttons/ButtonComp";

const ForgetPassword = () => {
  const { primary } = useColors();
  const style = useStyles();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: "20px",
        }}
      >
        <Typography sx={style.welcomText}>Forget Password</Typography>
      </Box>
      <Box sx={{ mt: 3, mx: "20px" }}>
        <Box sx={{ mb: 3 }}>
          <InputText id="email" label="Email address" type="email" />
        </Box>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <Link to="/login" style={{ textDecoration: "none" }}>
            <Typography
              variant="button"
              textAlign="right"
              component="a"
              sx={{ cursor: "pointer" }}
              // onClick={NavigatePassword}
            >
              Back to Login
            </Typography>
          </Link>
        </Stack>
        <Box>
          <ButtonComp
            text="Reset Password"
            type="submit"
            variant="contained"
            background={primary.dark}
            fullWidth
            disableElevation
          />
        </Box>
      </Box>
    </>
  );
};

export default ForgetPassword;
