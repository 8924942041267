import React from "react";
import useAdmin from "../hooks/useAdmin";
import { Typography } from "@mui/material";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";
import PaymentPage from "./PaymentPage";

const Payment = () => {
  const user = useAdmin((state) => state.user);
  return (
    <>
      {user.role === "student" ? (
        <>
          <HeaderTextButton headerName="Payment" />
          {user.isPaid === false ? <PaymentPage /> : <PaymentPage />}
        </>
      ) : (
        <Typography>Admin</Typography>
      )}
    </>
  );
};

export default Payment;
