import {
  Box,
  Stepper,
  Step,
  StepButton,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SnackBar from "../Components/Modals/SnackBar";
import AppDetails from "../Components/Container/AppDetails";
import AppInfo from "../Components/Container/AppInfo";
import AppResults from "../Components/Container/appResults";
import useAdmin from "../hooks/useAdmin";
import AxiosInstance from "../hooks/axiosInstance";
import AppUpload from "../Components/Container/AppUpload";
import AppConfirm from "../Components/Container/AppConfirm";

const steps = [1, 2, 3, 4, 5];

const Registration = () => {
  const user = useAdmin((state) => state.user);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const { isSubmitted, isSuccess, ismessage } = useSelector(
    (state: any) => state.form
  );

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const [formData, setFormData] = useState();

  const fetchCandidateData = async (id: string) => {
    try {
      const res = await AxiosInstance.get(`/students/${id}`);

      const data = res.data;
      setFormData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user._id) {
      fetchCandidateData(user._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <SnackBar
        isSubmitted={isSubmitted}
        isSuccess={isSuccess}
        ismessage={ismessage}
      />
      <Box sx={{ m: "20px" }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={index} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {/* {label} */}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Stack sx={{ mt: 2, mb: 1, py: 1 }}>
                {activeStep === 0 && (
                  <AppDetails handleNext={handleComplete} details={formData} />
                )}
                {activeStep === 1 && (
                  <AppInfo handleNext={handleComplete} details={formData} />
                )}
                {activeStep === 2 && (
                  <AppResults handleNext={handleComplete} details={formData} />
                )}
                {activeStep === 3 && (
                  <AppUpload handleNext={handleComplete} details={formData} />
                )}
                {activeStep === 4 && <AppConfirm details={formData} />}
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                  mt: "auto",
                }}
              >
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  onClick={handleNext}
                  sx={{ mr: 1 }}
                  disabled={activeStep === 4}
                >
                  Next
                </Button>
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
    </>
  );
};

export default Registration;
