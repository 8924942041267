import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent,
  Grid,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { states } from "../../utils/NigeriaState";
import AvatarUploader from "../Avatar/AvatarUploader";
import ButtonComp from "../Buttons/ButtonComp";
import { HeaderTextButton } from "../Header/HeaderTextButton";
import { InputText } from "../TextFields/InputText";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import AxiosInstance from "../../hooks/axiosInstance";
import {
  setLoading,
  setMessage,
  setSuccess,
  setSubmitted,
  clearMessage,
} from "../../utils/formSlice";
import { useColors } from "../../utils/useColor";
import { IStudents } from "../../utils/type";
import useAdmin from "../../hooks/useAdmin";

interface Props {
  handleNext: () => void;
  details: any;
}

const AppDetails: FC<Props> = ({ handleNext, details }) => {
  const updateUser = useAdmin((state) => state.updateUser);
  const { primary } = useColors();
  const dispatch = useDispatch();
  const [uploadImage, setUploadImage] = useState("");
  const handleUpload = async (data: string) => {
    setUploadImage(data);
    formik.setFieldValue("avatar", data);
  };
  const { isLoading } = useSelector((state: any) => state.form);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      otherName: "",
      gender: "",
      email: "",
      phoneNumber: "",
      address: "",
      state: "",
      lga: "",
      nationality: "",
      dob: "",
      avatar: uploadImage,
    },
    onSubmit: (value) => {
      dispatch(setLoading(true));
      AxiosInstance.put(`/students`, value)
        .then((res) => {
          dispatch(setMessage(res.data.message));
          updateUser(res.data.upload);
          dispatch(setSuccess(true));
          dispatch(setSubmitted(true));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
          handleNext();
        })
        .catch((error) => {
          dispatch(setSuccess(false));
          dispatch(setSubmitted(false));
          dispatch(setLoading(false));
          dispatch(setMessage(error.response.data.error));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });

  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [lgas, setLgas] = useState<{ code: string; name: string }[]>([]);

  useEffect(() => {
    if (selectedStateCode) {
      const selectedState = states.states.find(
        (state) => state.name === selectedStateCode
      );
      setLgas(selectedState ? selectedState.local_government_areas : []);
    } else {
      setLgas([]);
    }
  }, [selectedStateCode]);

  const handleStateChange = (event: SelectChangeEvent<string>) => {
    const stateCode = event.target.value as string;
    setSelectedStateCode(stateCode);
    formik.setFieldValue("state", stateCode);
    formik.setFieldValue("lga", "");
  };

  const setMultipleValues = (values: { [key: string]: any }) => {
    Object.keys(values).forEach((key) => {
      formik.setFieldValue(key, values[key]);
    });
  };

  const data = details as IStudents;
  const [isSubmitted, setIsSubmitted] = useState<Boolean>();

  useEffect(() => {
    setUploadImage(data?.avatar);
    formik.setFieldValue("avatar", data?.avatar);
    setSelectedStateCode(data?.state);
    setIsSubmitted(data?.isSubmitted);
    setMultipleValues({
      firstName: data?.firstName,
      lastName: data?.lastName,
      otherName: data?.otherName,
      gender: data?.gender,
      email: data?.email,
      phoneNumber: `0${data?.phoneNumber}`,
      address: data?.address,
      state: data?.state,
      lga: data?.lga,
      nationality: data?.nationality,
      dob: data?.dob,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <>
      <Box>
        <HeaderTextButton headerName="Applicants Details" />
        <Box sx={{ width: "100%" }}>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 6, sm: 2, md: 3 }}
              gap={{ lg: 8, xs: 5 }}
              justifyContent="center"
              mx="25px"
              display="flex"
              flexDirection={{ lg: "row", xs: "column" }}
            >
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                lg={2}
                display="flex"
                justifyContent={{ xs: "center", lg: "flex-start" }}
                alignItems="center"
              >
                <AvatarUploader
                  imageUrl={uploadImage ? uploadImage : ""}
                  handleUpload={handleUpload}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9} lg={10}>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column" },
                    justifyContent: "space-between",
                    gap: { lg: 3, xs: 0 },
                  }}
                >
                  <InputText
                    id="firstName"
                    label="First Name"
                    type="text"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                  <InputText
                    id="lastName"
                    label="Surname"
                    type="text"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                  <InputText
                    id="otherName"
                    label="Middle Name"
                    type="text"
                    value={formik.values.otherName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.otherName &&
                      Boolean(formik.errors.otherName)
                    }
                    helperText={
                      formik.touched.otherName && formik.errors.otherName
                    }
                  />
                </Box>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column" },
                    justifyContent: "space-between",
                    gap: { lg: 3, xs: 0 },
                  }}
                >
                  <Box sx={{ mb: "15px", width: "100%" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.gender && Boolean(formik.errors.gender)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="gender"
                        id="gender"
                        name="gender"
                        label="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.gender && Boolean(formik.errors.gender)
                        }
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.gender && formik.errors.gender && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                      >
                        {formik.errors.gender}
                      </Typography>
                    )}
                  </Box>
                  <InputText
                    id="email"
                    label="Email"
                    type="email"
                    disabled
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <InputText
                    id="phoneNumber"
                    label="Phone Number"
                    type="number"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                </Box>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column" },
                    justifyContent: "space-between",
                    gap: { lg: 3, xs: 0 },
                  }}
                >
                  <InputText
                    id="address"
                    label="Residential Address"
                    type="text"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />

                  <Box sx={{ mb: "15px", width: "100%" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        State of Origin
                      </InputLabel>
                      <Select
                        labelId="State of Origin"
                        id="state"
                        name="state"
                        label="State of Origin"
                        value={formik.values.state}
                        onChange={handleStateChange}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                      >
                        {states.states.map((state) => (
                          <MenuItem key={state.code} value={state.name}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {formik.touched.state && formik.errors.state && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                      >
                        {formik.errors.state}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ mb: "15px", width: "100%" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        Local Government
                      </InputLabel>
                      <Select
                        labelId="Local Government"
                        id="lga"
                        name="lga"
                        label="Local Government"
                        value={formik.values.lga}
                        onChange={formik.handleChange}
                        error={formik.touched.lga && Boolean(formik.errors.lga)}
                      >
                        {lgas.map((lga: any) => (
                          <MenuItem key={lga.code} value={lga.name}>
                            {lga.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {formik.touched.lga && formik.errors.lga && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                      >
                        {formik.errors.lga}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column" },
                    justifyContent: "space-between",
                    gap: { lg: 3, xs: 0 },
                  }}
                >
                  <InputText
                    id="nationality"
                    label="Nationality"
                    type="text"
                    value={formik.values.nationality}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.nationality &&
                      Boolean(formik.errors.nationality)
                    }
                    helperText={
                      formik.touched.nationality && formik.errors.nationality
                    }
                  />
                  <InputText
                    id="dob"
                    label=""
                    type="date"
                    value={formik.values.dob}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={formik.handleChange}
                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                    helperText={formik.touched.dob && formik.errors.dob}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                width: "40%",
                m: "auto",
                pt: "30px",
              }}
            >
              {isLoading ? (
                <LoadingButton loading variant="outlined" fullWidth>
                  Submit
                </LoadingButton>
              ) : (
                <ButtonComp
                  text="Save and Continue"
                  type="submit"
                  disabled={isSubmitted}
                  variant="contained"
                  background={primary.dark}
                  fullWidth
                  disableElevation
                />
              )}
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AppDetails;
