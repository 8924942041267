import { Stack, Grid, Box } from "@mui/material";
import { FC } from "react";
import { useColors } from "../../utils/useColor";
import { Helmet } from "react-helmet-async";
import { Identity, Logo } from "../../utils/constants";
import { useSelector } from "react-redux";
import SnackBar from "../Modals/SnackBar";

interface HomeLayoutProps {
  children: JSX.Element;
  title: string;
}

const HomeLayout: FC<HomeLayoutProps> = ({ children, title }) => {
  const { secondary } = useColors();
  const { isSubmitted, isSuccess, ismessage } = useSelector(
    (state: any) => state.form
  );

  return (
    <>
      <Helmet>
        <title>{`${Identity} | ${title}`}</title>
        <link rel="icon" href={Logo} />
      </Helmet>
      <SnackBar
        isSubmitted={isSubmitted}
        isSuccess={isSuccess}
        ismessage={ismessage}
      />
      <Stack sx={{ height: "100vh", position: "relative" }}>
        <Grid sx={{ width: "100%", height: "100%", display: "flex" }}>
          <Grid
            lg={6}
            sx={{
              background: secondary.dark,
              width: "100%",
              display: { xs: "none", lg: "block" },
            }}
          ></Grid>
        </Grid>
        <Stack
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              background: "#fff",
              width: "45%",
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                width: "90%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{ width: "100px", height: "100px", overflow: "hidden" }}
                >
                  <img
                    src={Logo}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Box>

              {children}
            </Box>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default HomeLayout;
