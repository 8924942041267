import { Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import useAdmin from "../hooks/useAdmin";
import { useNavigate } from "react-router-dom";
import Registration from "./Registration";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";

const Dashboard = () => {
  const user = useAdmin((state) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (user.isPaid === false) {
      navigate("/payment");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <>
      {user.role === "student" ? (
        <>
          <HeaderTextButton headerName="Dashboard" />
          <Stack
            sx={{
              background: "#fff",
              mt: "20px",
              height: "100%",
              display: "flex",
              // overflow: "hidden",
              // flexWrap: "wrap",
              // alignContent: "center",
              // justifyContent: "center",
            }}
          >
            <Registration />
          </Stack>
        </>
      ) : (
        <Typography>Admin</Typography>
      )}
    </>
  );
};

export default Dashboard;
