import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { HeaderTextButton } from "../Header/HeaderTextButton";
import { useFormik } from "formik";
import { InputText } from "../TextFields/InputText";
import { LoadingButton } from "@mui/lab";
import ButtonComp from "../Buttons/ButtonComp";
import { useColors } from "../../utils/useColor";
import { useDispatch, useSelector } from "react-redux";
import {
  DirectEntry,
  examType,
  GradeMarks,
  SubjectsMark,
} from "../../utils/NigeriaState";
import { IStudents } from "../../utils/type";
import {
  setLoading,
  setMessage,
  setSuccess,
  setSubmitted,
  clearMessage,
} from "../../utils/formSlice";
import AxiosInstance from "../../hooks/axiosInstance";
import useAdmin from "../../hooks/useAdmin";

interface Props {
  handleNext: () => void;
  details: any;
}

const AppResults: FC<Props> = ({ handleNext, details }) => {
  const updateUser = useAdmin((state) => state.updateUser);
  const { primary } = useColors();
  const { isLoading } = useSelector((state: any) => state.form);

  const [numberOfSitting, setNumberOfSitting] = useState<number>(1);

  const handleChangesittingNumber = (event: SelectChangeEvent) => {
    const data = parseInt(event.target.value);
    setNumberOfSitting(data);
  };

  const dispatch = useDispatch();

  const olevel = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const jupeb = [1, 2, 3];

  const jamb = [1, 2, 3, 4];

  const data = details as IStudents;
  const [isSubmitted, setIsSubmitted] = useState<Boolean>();
  useEffect(() => {
    if (data) {
      setIsSubmitted(data?.isSubmitted);
      const sittingLength = data.Olevels.sittings.length;
      setNumberOfSitting(sittingLength);

      data.Olevels.sittings.forEach((sitting, idx) => {
        formik.setFieldValue(
          `Olevels.sittings[${idx}].examType`,
          sitting.examType
        );
        formik.setFieldValue(
          `Olevels.sittings[${idx}].examNumber`,
          sitting.examNumber
        );
        formik.setFieldValue(
          `Olevels.sittings[${idx}].examYear`,
          sitting.examYear
        );

        sitting.subjects.forEach((subject, sIdx) => {
          formik.setFieldValue(
            `Olevels.sittings[${idx}].subjects[${sIdx}].examSubject`,
            subject.examSubject
          );
          formik.setFieldValue(
            `Olevels.sittings[${idx}].subjects[${sIdx}].examGrade`,
            subject.examGrade
          );
        });
      });

      data.SubjectCombination.forEach((subject, idx) => {
        formik.setFieldValue(
          `SubjectCombination[${idx}].discipline`,
          subject.discipline
        );
        formik.setFieldValue(
          `SubjectCombination[${idx}].subject`,
          subject.subject
        );
        formik.setFieldValue(
          `SubjectCombination[${idx}].remark`,
          subject.remark
        );
      });

      data.utme.subject.forEach((subject, idx) => {
        formik.setFieldValue(`utme.subject[${idx}].name`, subject.name);
        formik.setFieldValue(`utme.subject[${idx}].score`, subject.score);
      });

      data.transfer.courses.forEach((course, idx) => {
        formik.setFieldValue(`transfer.courses[${idx}].name`, course.name);
        formik.setFieldValue(`transfer.courses[${idx}].score`, course.score);
      });

      formik.setFieldValue(`utme.jambReg`, data.utme.jambReg);
      formik.setFieldValue(`transfer.resultType`, data.transfer.resultType);
      formik.setFieldValue(`transfer.resultYear`, data.transfer.resultYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const formik = useFormik({
    initialValues: {
      Olevels: {
        sittings: Array.from({ length: numberOfSitting }, () => ({
          examType: "",
          examNumber: "",
          examYear: "",
          subjects: Array.from({ length: 9 }, () => ({
            examSubject: "",
            examGrade: "",
          })),
        })),
      },
      SubjectCombination: Array.from({ length: 3 }, () => ({
        discipline: "",
        subject: "",
        remark: "",
      })),
      utme: {
        jambReg: "",
        subject: Array.from({ length: 4 }, () => ({
          name: "",
          score: "",
        })),
      },
      transfer: {
        resultType: "",
        resultYear: "",
        courses: Array.from({ length: 3 }, () => ({
          name: "",
          score: "",
        })),
      },
    },
    onSubmit: (value) => {
      dispatch(setLoading(true));
      AxiosInstance.put(`/students`, value)
        .then((res) => {
          dispatch(setMessage(res.data.message));
          updateUser(res.data.upload);
          dispatch(setSuccess(true));
          dispatch(setSubmitted(true));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
          handleNext();
        })
        .catch((error) => {
          dispatch(setSuccess(false));
          dispatch(setSubmitted(false));
          dispatch(setLoading(false));
          dispatch(setMessage(error.response.data.error));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });

  return (
    <>
      <Box sx={{ mx: "90px" }}>
        <HeaderTextButton headerName="O'Level Results" />
        <Box sx={{ width: "100%", mt: "10px" }}>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Box sx={{ mb: "15px", width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-select-small-label">
                  Number of sittings
                </InputLabel>
                <Select
                  labelId="Number of sittings"
                  id="numberOfSitting"
                  name="numberOfSitting"
                  label="Number of sittings"
                  value={numberOfSitting.toString()}
                  onChange={handleChangesittingNumber}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                </Select>
              </FormControl>
            </Box>

            {[...Array(numberOfSitting)].map((_, idx) => (
              <>
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {idx === 0 ? "First Sitting" : "Second Sitting"}{" "}
                  </Typography>
                </Box>
                <Box sx={{ mt: "20px" }} key={idx}>
                  <Box
                    sx={{
                      mb: 1,
                      display: "flex",
                      flexDirection: { lg: "row", xs: "column" },
                      justifyContent: "space-between",
                      gap: { lg: 3, xs: 0 },
                    }}
                  >
                    <Box sx={{ mb: "15px", width: "100%" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-select-small-label">
                          Exam Type
                        </InputLabel>
                        <Select
                          labelId="Exam Type"
                          id={`Olevels.sittings[${idx}].examType`}
                          name={`Olevels.sittings[${idx}].examType`}
                          label="Exam Type"
                          value={formik.values.Olevels.sittings[idx]?.examType}
                          onChange={formik.handleChange}
                        >
                          {examType.map((data, index) => (
                            <MenuItem key={index} value={data.name}>
                              {data.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <InputText
                      id={`Olevels.sittings[${idx}].examNumber`}
                      name={`Olevels.sittings[${idx}].examNumber`}
                      label="Exam Number"
                      type="text"
                      value={formik.values.Olevels.sittings?.[idx]?.examNumber}
                      onChange={formik.handleChange}
                    />
                    <InputText
                      id={`Olevels.sittings[${idx}].examYear`}
                      name={`Olevels.sittings[${idx}].examYear`}
                      label="Exam Year"
                      type="text"
                      value={formik.values.Olevels.sittings?.[idx]?.examYear}
                      onChange={formik.handleChange}
                    />
                  </Box>

                  {olevel &&
                    olevel.map((_data, index) => (
                      <Box
                        key={index}
                        sx={{
                          mb: 1,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: { lg: 3, xs: 2 },
                        }}
                      >
                        <Box sx={{ mb: "15px", width: "100%" }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-select-small-label">
                              Subject
                            </InputLabel>
                            <Select
                              // labelId={`examSubject-label-${idx}-${index}`}
                              id={`Olevels.sittings[${idx}].subjects[${index}].examSubject`}
                              name={`Olevels.sittings[${idx}].subjects[${index}].examSubject`}
                              label="Subject"
                              value={
                                formik.values.Olevels.sittings?.[idx]?.subjects[
                                  index
                                ]?.examSubject
                              }
                              onChange={formik.handleChange}
                            >
                              {SubjectsMark.map((data, index) => (
                                <MenuItem key={index} value={data.name}>
                                  {data.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box sx={{ mb: "15px", width: "100%" }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-select-small-label">
                              Grade
                            </InputLabel>
                            <Select
                              labelId={`examGrade-label-${idx}-${index}`}
                              id={`Olevels.sittings[${idx}].subjects[${index}].examGrade`}
                              name={`Olevels.sittings[${idx}].subjects[${index}].examGrade`}
                              label="Grade"
                              value={
                                formik.values.Olevels.sittings?.[idx]?.subjects[
                                  index
                                ]?.examGrade
                              }
                              onChange={formik.handleChange}
                            >
                              {GradeMarks.map((data, index) => (
                                <MenuItem key={index} value={data.name}>
                                  {data.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </>
            ))}

            {data && data?.programme === "A-Level" &&
              (data.course === "JUPEB" || data.course === "IJMB") && (
                <>
                  <HeaderTextButton headerName="Subject combination for foundation program (JUPEB, IJMB)" />
                  <Box sx={{ mt: "20px" }}>
                    {jupeb &&
                      jupeb.map((_data, index) => (
                        <Box
                          key={index}
                          sx={{
                            mb: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            gap: { lg: 3, xs: 2 },
                          }}
                        >
                          <InputText
                            id={`SubjectCombination.${index}.discipline`}
                            label="Discipline"
                            type="text"
                            value={
                              formik.values.SubjectCombination[index]
                                ?.discipline
                            }
                            onChange={formik.handleChange}
                          />
                          <InputText
                            id={`SubjectCombination.${index}.subject`}
                            label="Subject Combination"
                            type="text"
                            value={
                              formik.values.SubjectCombination[index]?.subject
                            }
                            onChange={formik.handleChange}
                          />
                          <InputText
                            id={`SubjectCombination.${index}.remark`}
                            label="Remarks"
                            type="text"
                            value={
                              formik.values.SubjectCombination[index]?.remark
                            }
                            onChange={formik.handleChange}
                          />
                        </Box>
                      ))}
                  </Box>
                </>
              )}

            {data && data?.programme === "Undergraduates" && data.appType === "UTME" && (
              <>
                <HeaderTextButton headerName="UTME Results" />
                <Box sx={{ mt: "20px" }}>
                  <InputText
                    id={`utme.jambReg`}
                    label="Jamb Reg"
                    type="text"
                    value={formik.values.utme.jambReg}
                    onChange={formik.handleChange}
                  />
                  {jamb &&
                    jamb.map((_, index) => (
                      <Box
                        key={index}
                        sx={{
                          mb: 1,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: { lg: 3, xs: 2 },
                        }}
                      >
                        <Box sx={{ mb: "15px", width: "100%" }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-select-small-label">
                              Subject
                            </InputLabel>
                            <Select
                              labelId={`utme.subject[${index}].name`}
                              id={`utme.subject[${index}].name`}
                              name={`utme.subject[${index}].name`}
                              label="Subject"
                              value={formik.values.utme.subject[index]?.name}
                              onChange={formik.handleChange}
                            >
                              {SubjectsMark.map((data, index) => (
                                <MenuItem key={index} value={data.name}>
                                  {data.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <InputText
                          id={`utme.subject[${index}].score`}
                          label="Score"
                          type="text"
                          value={formik.values.utme.subject[index]?.score}
                          onChange={formik.handleChange}
                        />
                      </Box>
                    ))}
                </Box>
              </>
            )}

            {data && (data.appType === "Direct Entry" ||
              data.appType === "Transfer") && (
              <>
                <HeaderTextButton headerName="Direct Entry / Transfer Results" />
                <Box sx={{ mt: "20px" }}>
                  <Box
                    sx={{
                      mb: 1,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: { lg: 3, xs: 2 },
                    }}
                  >
                    <Box sx={{ mb: "15px", width: "100%" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-select-small-label">
                          Result Type
                        </InputLabel>
                        <Select
                          labelId={`transfer.resultType`}
                          id={`transfer.resultType`}
                          name={`transfer.resultType`}
                          label="Result Type"
                          value={formik.values.transfer.resultType}
                          onChange={formik.handleChange}
                        >
                          <MenuItem value="NCE">NCE</MenuItem>
                          <MenuItem value="Diploma">Diploma</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <InputText
                      id={`transfer.resultYear`}
                      label="Result Year"
                      type="text"
                      value={formik.values.transfer.resultYear}
                      onChange={formik.handleChange}
                    />
                  </Box>

                  {jupeb &&
                    jupeb.map((_data, index) => (
                      <Box
                        key={index}
                        sx={{
                          mb: 1,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: { lg: 3, xs: 2 },
                        }}
                      >
                        <Box sx={{ mb: "15px", width: "100%" }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-select-small-label">
                              Course
                            </InputLabel>
                            <Select
                              labelId={`transfer.courses[${index}].name`}
                              id={`transfer.courses[${index}].name`}
                              name={`transfer.courses[${index}].name`}
                              label="Course"
                              value={
                                formik.values.transfer.courses[index]?.name
                              }
                              onChange={formik.handleChange}
                            >
                              {DirectEntry.map((data, index) => (
                                <MenuItem key={index} value={data.name}>
                                  {data.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <InputText
                          id={`transfer.courses[${index}].score`}
                          label="Point"
                          type="text"
                          value={formik.values.transfer.courses[index]?.score}
                          onChange={formik.handleChange}
                        />
                      </Box>
                    ))}
                </Box>
              </>
            )}

            <Box
              sx={{
                width: "40%",
                m: "auto",
                pt: "30px",
              }}
            >
              {isLoading ? (
                <LoadingButton loading variant="outlined" fullWidth>
                  Submit
                </LoadingButton>
              ) : (
                <ButtonComp
                  text="Save and Continue"
                  type="submit"
                  disabled={isSubmitted}
                  variant="contained"
                  background={primary.dark}
                  fullWidth
                  disableElevation
                />
              )}
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AppResults;
