import { FC, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useColors } from "../../utils/useColor";
import DehazeOutlinedIcon from "@mui/icons-material/DehazeOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { Identity, Logo } from "../../utils/constants";
import HomeIcon from "@mui/icons-material/Home";
import { Link, useLocation } from "react-router-dom";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ActionModal from "../Modals/ActionModal";
import useAdmin from "../../hooks/useAdmin";
import axiosInstance from "../../hooks/axiosInstance";
import { IStudents } from "../../utils/type";

interface AuthLayoutProps {
  children: JSX.Element;
  title: String;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children, title }) => {
  const location = useLocation();
  const linkPath = location.pathname.split("/")[1];
  // const [inputValue, setInputValue] = useState("");
  const drawerWidth = 240;

  const { primary } = useColors();

  const [istoggle, setIsToggle] = useState(false);

  const handleToggle = () => {
    setIsToggle(!istoggle);
  };
  const user = useAdmin((state) => state.user);

  // Modal
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logOut = () => {
    // destroy the cookie
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("ADMIN");
    // redirect user to the landing page
    window.location.href = "/";
  };

  const [formData, setFormData] = useState<IStudents>();

  const fetchCandidateData = async (id: string) => {
    try {
      const res = await axiosInstance.get(`/students/${id}`);

      const data = res.data;
      setFormData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user._id) {
      fetchCandidateData(user._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Helmet>
        <title>{`${Identity} | ${title}`}</title>
        {/* <meta name='description' content='ShoutPond, home of ideas' /> */}
        <link rel="icon" href={Logo} />
      </Helmet>

      {/* App bar */}
      <AppBar
        sx={{
          position: "fixed",
          width: { md: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
          ml: `${drawerWidth}px`,
          background: "#fff",
          color: primary.dark,
          zIndex: 9,
        }}
        elevation={1}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            flexGrow={1}
            onClick={handleToggle}
          >
            <DehazeOutlinedIcon sx={{ display: { md: "none", xs: "block" } }} />
          </Typography>

          {/* {Logo}
          </Avatar> */}
        </Toolbar>
      </AppBar>
      {/* Fixed sidebar */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          display: { xs: istoggle ? "block" : "none", md: "block" },
          position: "fixed",
          height: "100vh",
          overflow: "hidden",
        }}
        variant="permanent"
        anchor="left"
        classes={{ paper: "100%" }}
      >
        <Stack position="relative" sx={{ height: "100vh", overflow: "hidden" }}>
          <Box
            sx={{
              position: "absolute",
              zIndex: 99999,
              right: "0%",
              mt: "20px",
              mr: "10px",
            }}
            onClick={handleToggle}
          >
            <ArrowBackIosOutlinedIcon
              sx={{ display: { md: "none", xs: "block" } }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: "20px",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "45px", height: "45px", overflow: "hidden" }}>
              <img
                src={Logo}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="div"
              fontWeight="bold"
              color="primary"
            >
              {Identity}
            </Typography>
          </Box>
          <Box textAlign="center" lineHeight="100vh" py={"10px"}>
            <Box display={"flex"} justifyContent={"center"} pb={"10px"}>
              <Avatar
                sx={{ width: 70, height: 70 }}
                src={formData ? formData.avatar : ""}
              >
                {!user.avatar && `${user.lastName[0]}${user.firstName[0]}`}
              </Avatar>
            </Box>
            <Typography
              fontWeight="bold"
              color="primary"
              sx={{ textTransform: "capitalize" }}
            >
              {user.role.toLowerCase()}
            </Typography>
            <Typography fontWeight="bold">
              {" "}
              {`${user.lastName} ${user.firstName}`}
            </Typography>
          </Box>
          <Divider />

          <List
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              height: "100%",
            }}
          >
            <Box sx={{ overflowY: "auto", height: "90%" }}>
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: linkPath === "dashboard" ? "#fff" : "#000",
                }}
              >
                <ListItem
                  key="Dashboard"
                  disablePadding
                  sx={{
                    display: "block",
                    background: linkPath === "dashboard" ? primary.dark : "",
                    "& .icon": {
                      color: linkPath === "dashboard" ? "#fff" : "",
                    },
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: "center",
                      }}
                    >
                      <HomeIcon color="primary" className="icon" />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                </ListItem>
              </Link>
              <Link
                to="/payment"
                style={{
                  textDecoration: "none",
                  color: linkPath === "payment" ? "#fff" : "#000",
                }}
              >
                <ListItem
                  key="Payment"
                  disablePadding
                  sx={{
                    display: "block",
                    background: linkPath === "payment" ? primary.dark : "",
                    "& .icon": {
                      color: linkPath === "payment" ? "#fff" : "",
                    },
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: "center",
                      }}
                    >
                      <HomeIcon color="primary" className="icon" />
                    </ListItemIcon>
                    <ListItemText primary="Payment" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </Box>

            <ListItem
              key="Logout"
              disablePadding
              sx={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                background: primary.dark,
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 30,
                  justifyContent: "center",
                  px: 2.5,
                  color: "#fff",
                }}
                onClick={handleOpen}
              >
                <ListItemText primary="Logout" />
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    justifyContent: "center",
                  }}
                >
                  <LogoutOutlinedIcon sx={{ color: "#fff" }} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </List>
        </Stack>
      </Drawer>
      {/* {showOverlay && (
        <Overlay
          inputValue={inputValue}
          handleInputChange={handleInputChange}
          handleButtonClick={handleButtonClick}
        />
      )} */}
      {/* Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          ml: { md: `${drawerWidth}px`, position: "relative" },
        }}
      >
        <Toolbar />
        {/* Later update to add a drawer with some primary site settings */}
        {/* <Box
          sx={{
            width: "45px",
            height: "45px",
            background: "red",
            position: "fixed",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          12
        </Box> */}
        {children}
      </Box>

      <ActionModal
        open={open}
        handleClose={handleClose}
        status="error"
        message="Are you sure you want to log out?"
        confirmAction={logOut}
      />
    </>
  );
};

export default AuthLayout;
